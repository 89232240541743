import React, { useState } from "react";
import { Map, Marker, InfoWindow, GoogleApiWrapper } from "google-maps-react";

export const MapContainer = (props) => {
  const [selectedPlace, setSelectedPlace] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace({ marker, ...props });
    setShowingInfoWindow(true);
  };

  return (
    <Map
      google={props.google}
      zoom={18}
      initialCenter={{
        lat: 35.626315,
        lng: 139.7173663,
      }}
    >
      <Marker
        onClick={onMarkerClick}
        name={"Current location"}
      />

      <InfoWindow
        marker={selectedPlace.marker}
        visible={showingInfoWindow}
      >
        <div>
          <h4>{selectedPlace.name}</h4>
        </div>
      </InfoWindow>
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyC2j-3isMZae9axqB3nBkjzuoXpfRwkF84",
})(MapContainer);
